<template>
  <div>
    <q-form ref="editForm">
      <c-card :title="title" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <div v-html="data.content"></div>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'first-manual-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sysMenuId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      title: '',
      detailUrl: '',
      data: {
        sysMenuId: '',  // 메뉴 SEQ
        menuNm: '',  // 메뉴 SEQ
        content: '',  // 매뉴얼 내용
        useFlag: 'Y',  // 사용여부
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    init() {
      // url setting
      this.detailUrl = selectConfig.sys.manual.get.url;
      this.getData();
    },
    getData() {
      this.$http.url = this.$format(this.detailUrl, 'MENU000303');
      this.$http.type = 'GET';
      this.$http.request((_result) => {

        console.log(_result.data)
        this.data = _result.data;
        this.title = '[매뉴얼] ' + this.data.menuNm;
      },
      () => {
      });
    },
  }
};
</script>
