var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            { staticClass: "cardClassDetailForm", attrs: { title: _vm.title } },
            [
              _c("template", { slot: "card-detail" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.data.content) },
                  }),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }